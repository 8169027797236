body,
html,
#root {
  scroll-behavior: smooth;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  background-color: #fff;
  max-height: '2500px';
}

* {
  font-family: 'Lato', sans-serif;
  font-family: 'Oxygen', sans-serif;
  font-family: 'Varela Round', sans-serif;
}