pre {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}

#root {
  min-height: 100vh;
  max-width: 100vw;
}

body {
  display: flex;
  flex-direction: column;
}

header {
  height: 58px;
  width: 100vw;
}

footer {
  height: 56px;
  width: 100vw;
}

main {
  height: fit-content;
  min-height: calc(100vh);
  max-width: 100vw;
  width: 100%;
  overflow: hidden;
}